<!--
 * @Description: 公众号 停车记录 parkRecord
 * @Author: zhoucheng
 * @Github: 无
 * @Date: 2021-04-01 01:02:08
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="停车记录" />
    <van-dropdown-menu>
      <van-dropdown-item v-model="plateVal"
                         :options="plateList"
                         @change="plateChange" />
    </van-dropdown-menu>
    <van-row class="list-box">
      <van-list class="list"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad">
        <van-row class="card-box"
                 v-for="(item,index) in parkingList"
                 :key="index"
                 :title="item">
          <card :cardData="item"
                @clickRule="clickRule"></card>
        </van-row>
      </van-list>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'
import parkStopTime from '@/utils/parkStopTime'
import card from './card'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    card
  },
  data () {
    // 这里存放数据
    return {
      plateVal: 0,
      plateList: [], // 车牌列表
      loading: false,
      finished: true,
      parkingList: [], // 停车订单列表
      currentTime: this.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    getUserInfo().then(() => {
      this.getPlate()
    })
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取车牌
    getPlate () {
      this.$parkRecord.queryBindPlateNumber({}).then(res => {
        const data = res.resultEntity
        const list = []
        for (const index in data) {
          const info = {
            text: data[index].plateNumber, value: data[index].plateNumber
          }
          list.push(info)
        }
        this.plateVal = list[0].value
        this.plateList = list
        this.$payForParkCount.queryCompletedOrderByPlateNumber({ plateNumber: this.plateVal }).then(res => {
          const data = res.resultEntity
          for (const index in data) {
            // data[index].receivableMoneyAmount = (Number(data[index].receivableMoneyAmount) / 100).toFixed(2)
            if (data[index].leaveTime) {
              data[index].parkDurationFmt = parkStopTime(data[index].entranceTime, data[index].leaveTime)
            } else {
              data[index].parkDurationFmt = parkStopTime(data[index].entranceTime, this.currentTime)
            }
          }
          this.parkingList = data
        })
      })
    },
    // 选中车牌
    plateChange (val) {
      this.$payForParkCount.queryCompletedOrderByPlateNumber({ plateNumber: val }).then(res => {
        const data = res.resultEntity
        for (const index in data) {
          data[index].receivedMoneyAmount = Number(data[index].receivedMoneyAmount) / 100
          if (data[index].leaveTime) {
            data[index].parkDurationFmt = parkStopTime(data[index].entranceTime, data[index].leaveTime)
          } else {
            data[index].parkDurationFmt = parkStopTime(data[index].entranceTime, this.currentTime)
          }
        }
        this.parkingList = data
        if (res.resultEntity.length > 0) {
          this.billingRuleDefId = res.resultEntity[0].billingRuleDefId
        }
      })
    },
    onLoad () {
      console.log('列表加载')
    },
    // 点击收费规则
    clickRule (cardData) {
      this.$router.push({
        name: 'billRuleDetail',
        query: {
          parkId: cardData.parkId
        }
      })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: {
      //     billingRuleDefId: cardData.billingRuleDefId,
      //     page: 'parkingRecord'
      //   }
      // })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.list-box {
  height: calc(100vh - 110px - 12px);
  width: 100%;
  overflow: auto;
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    .card-box {
      width: 95%;
      margin: 12px 0 0;
      border-radius: 5px;
      overflow: hidden;
    }
  }
}
</style>
